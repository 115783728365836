import 'slick-carousel';
import jQuery from 'jquery';
window.jQuery = window.$ = jQuery;

class LogoSlide extends HTMLElement {
  constructor() {
    super();
    this.jQuery = jQuery;
    this.$ = this.jQuery;
  }

  connectedCallback() {
    this.initSlider();
  }

  initSlider() {
    this.$('.logo-slide', this).slick({
      slidesToShow: 2,
      slidesToScroll: 2,
      prevArrow:
        '<button class="slide-arrow prev-arrow absolute left-0 top-1/2 translate-y-[-50%]"><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1L1 7L7 13" stroke="#61554A"/></svg></button>',
      nextArrow:
        '<button class="slide-arrow next-arrow absolute right-0 top-1/2 translate-y-[-50%]"><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 13L7 7L1 1" stroke="#61554A"/></svg></button>'
    });
  }
}

customElements.define('logo-slide', LogoSlide);
